@import 'material-icons/iconfont/material-icons.css';

/* You can add global styles to this file, and also import other style files */
a:link,
a:visited {
    text-decoration: none;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.material-icons {
    font-family: 'Material Icons' !important;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* roboto-300 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */
        url('/assets/fonts/roboto-v30-latin-300.woff') format('woff');
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */
        url('/assets/fonts/roboto-v30-latin-regular.woff') format('woff');
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/roboto-v30-latin-500.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */
        url('/assets/fonts/roboto-v30-latin-500.woff') format('woff');
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
